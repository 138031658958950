.body {
    min-height: 100vh;
}

.slider1:hover .swiper-container--th {
    transform: translateY(0px);
}

.swiper-button-next {
    background-image: none;
    right: 3em;
}

.swiper-button-prev {
    background-image: none;
    left: 3em;
}

@media (max-width: 991px) {
    .slider1 .swiper-container--th {
        transform: translateY(20px);
    }
}

@media (max-width: 767px) {
    .slider1 .swiper-container--th {
        transform: translateY(0px);
    }
    .swiper-slide-active .slide_cont--th {}
}

@media (max-width: 479px) {}